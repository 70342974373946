import React, { useState } from "react";
import Header from "../../components/Header";
import { Navigate } from "react-router-dom";
import AudioRecorder from "../../components/AudioRecorder";
import {
  Button,
  CircularProgress,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { parseData } from "../../utils";
import { sendDataCollection } from "../../services/form";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { resetForm } from "../../features/form/formSlice";
interface RecordingFile {
  [key: string]: File | number;
}

const Recording = () => {
  const [recordings, setRecordings] = useState<RecordingFile>({});
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const playerInUse = useSelector(
    (state: RootState) => state.global.playerInUse
  );

  const handleSave = (data: { [key: string]: File | number }) => {
    setRecordings((prevRecordings) => ({
      ...prevRecordings,
      ...data,
    }));
    console.log(recordings);
  };

  const individualDetails = useSelector(
    (state: RootState) => state.form.individualDetails
  );
  const responses = useSelector((state: RootState) => state.form.responses);

  const audioRecorders = [
    {
      pk: 1,
      key: "cough_recording_1",
      title: "Cough sound recording-1",
      subtitle: "Please record cough bouts for a minimum of 5 seconds",
      minDuration: 5,
      onSave: handleSave,
    },
    {
      pk: 2,
      key: "cough_recording_2",
      title: "Cough sound recording-2",
      subtitle: "Please record cough bouts for a minimum of 5 seconds",
      minDuration: 5,
      onSave: handleSave,
    },
    {
      pk: 3,
      key: "cough_recording_3",
      title: "Cough sound recording-3",
      subtitle: "Please record cough bouts for a minimum of 5 seconds",
      minDuration: 5,
      onSave: handleSave,
    },
    {
      pk: 4,
      key: "background_sound",
      title: "Background sound of the recording room",
      subtitle: "Please record the background/ambient sound for 10 seconds",
      minDuration: 10,
      onSave: handleSave,
    },
  ];

  const allRecordingsPresent = audioRecorders.every(
    (recorder) => recordings[recorder.key]
  );

  const handleSubmit = async () => {
    if (allRecordingsPresent) {
      setLoading(true);
      const data = { individualDetails, responses, recordings };
      const formData = parseData(data);

      try {
        const response = await sendDataCollection(formData);
        if (response.ok) {
          setLoading(false);
          setModalOpen(true);
          setTimeout(() => {
            dispatch(resetForm());
          }, 5000); // Redirect after 5 seconds
        }else{
          alert("Something went wrong!")
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
      }finally{
        setLoading(false);
      }
    } else {
      console.log("Please complete all recordings before submitting.");
    }
  };
  if (!individualDetails.nikshayId || !responses)
    return <Navigate to="/patient-details" />;
  return (
    <>
      <Header name={"Cough recordings"} />
      {audioRecorders.map((recorder) => (
        <AudioRecorder
          pk={recorder.pk}
          key={recorder.key}
          id={recorder.key}
          title={recorder.title}
          subtitle={recorder.subtitle}
          minDuration={recorder.minDuration}
          onSave={recorder.onSave}
        />
      ))}
      <Box textAlign="center" my={2}>
        <Button
          endIcon={<ArrowForwardIcon />}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            backgroundColor: "#02A479",
            borderRadius: "16px",
            width: "40%",
            alignSelf: "center",
          }}
          disabled={!allRecordingsPresent || playerInUse || loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
        </Button>
      </Box>

      {/* Success Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            Upload Successful
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Redirecting you to the home page in 5 seconds...
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(resetForm())}
            sx={{ mt: 3 }}
          >
            Go to Home Page
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Recording;

import { getAuthToken } from ".";
import { BASE_URL } from "../constants/api";

const ENDPOINTS = {
  DATA_COLLECTION: "data/recordings/create/",
  USER_COUNT: "data/user/record-count/"
};

const headers = (token: string) => ({
  Authorization: `Token ${token}`,
});

// General method for sending a POST request with FormData
const apiService = async (
  endpointPath: string,
  method: string,
  data: FormData
) => {
  const token = await getAuthToken();
  const response = await fetch(`${BASE_URL}${endpointPath}`, {
    method,
    headers: token ? headers(token) : {},
    body: data,
  });

  return response;
};

// Data collection function that sends FormData to the server
export const sendDataCollection = async (formData: FormData) =>
  apiService(ENDPOINTS.DATA_COLLECTION, "POST", formData);



export const getUserRecordCount = async () => {
  const token = await getAuthToken();
  const response = await fetch(`${BASE_URL}${ENDPOINTS.USER_COUNT}`, {
    method: "GET",
    headers: token ? headers(token) : {},
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user record count");
  }

  const data = await response.json();
  return data;  // Assuming the server returns the count in a JSON format
};


import { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Modal,
  Box as MuiBox,
  CircularProgress,
} from "@mui/material";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setIndividualDetails } from "../../features/form/formSlice";
import { validateAndFetch } from "../../services/nikshay";
import { getDeviceDetails, getUserLocation } from "../../utils";
import { getUserRecordCount } from "../../services/form";

const IndividualDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const individualDetails = useSelector(
    (state: RootState) => state.form.individualDetails
  );
  const [localDetails, setLocalDetails] = useState(individualDetails);
  const [error, setError] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [count, setCount] = useState(0);

  const handleValidate = async () => {
    if (
      !localDetails.nikshayId ||
      isNaN(Number(localDetails.nikshayId)) ||
      Number(localDetails.nikshayId) <= 0 ||
      localDetails.nikshayId.length > 15
    ) {
      setError("Please enter a valid Ni-kshay ID with a maximum length of 15 digits.");
      return;
    }
    setIsLoading(true); // Start loading

    const deviceDetails = getDeviceDetails();
    const locationDetails = await getUserLocation();
    try {
      const response = await validateAndFetch(localDetails.nikshayId);
      const content = await response.json();
      setIsLoading(false); // Stop loading once the response is received
      if (response.ok) {
        setError(""); // Clear errors
        const data = content.data;
        setLocalDetails({
          nikshayId: localDetails.nikshayId,
          deviceDetails: deviceDetails,
          locationDetails: locationDetails,
          ...data,
        });
        setModalMessage("The Ni-kshay ID is valid. Please confirm the details.");
        setIsSuccess(true);
        setOpenModal(true);
      } else if (response.status === 400) {
        setModalMessage("The Ni-kshay ID is not valid.");
        setIsSuccess(false);
        setOpenModal(true);
      } else {
        setError("An error occurred while validating the ID.");
      }
    } catch (error) {
      setIsLoading(false); // Stop loading on error
      console.error("Error:", error);
      setError("An error occurred while validating the ID.");
    }
  };

  const handleSave = () => {
    dispatch(
      setIndividualDetails({
        ...localDetails,
      })
    );
    navigate("/symptoms");
  };

  const handleClose = () => {
    setOpenModal(false);
    setLocalDetails({
      nikshayId: "",
      name: "",
      age: "",
      gender: "",
      phone_number: "",
      deviceDetails: {
        userAgent: "",
      },
      locationDetails: null,
    });
  };

  const getCountOfRecords = async () => {
    try {
      const userCountData = await getUserRecordCount();
      setCount(userCountData?.record_count);
    } catch (error) {}
  };

  useEffect(() => {
    getCountOfRecords();
  }, []);

  return (
    <>
      <Header name={"Individual Details"} />
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" mt={4}>
          <Typography variant="h6" fontWeight="bold">
            Ni-kshay ID
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            type="number"
            value={localDetails.nikshayId}
            onChange={(e) =>
              setLocalDetails({ ...localDetails, nikshayId: e.target.value })
            }
            error={!!error}
            helperText={error}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              backgroundColor: "#02A479",
              borderRadius: "16px",
              width: "40%",
              alignSelf: "center",
            }}
            endIcon={<ArrowForwardIcon />}
            onClick={handleValidate}
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : "Validate"}
          </Button>
          {count ? (
            <Typography sx={{ alignSelf: "center", mt: 2 }}>
              Total records collected: {count}
            </Typography>
          ) : null}
        </Box>
      </Container>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <MuiBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {isSuccess ? "Validation Successful" : "Validation Failed"}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>

          {isLoading ? (
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            isSuccess && (
              <Box mt={2}>
                <Typography variant="body1">
                  <strong>Ni-kshay ID:</strong> {localDetails.nikshayId}
                </Typography>
                <Typography variant="body1">
                  <strong>Name:</strong> {localDetails.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Age:</strong> {localDetails.age}
                </Typography>
                <Typography variant="body1">
                  <strong>Gender:</strong> {localDetails.gender}
                </Typography>
                <Typography variant="body1">
                  <strong>Phone:</strong> {localDetails.phone_number}
                </Typography>
              </Box>
            )
          )}

          <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
            <Button onClick={handleClose} color="secondary" variant="outlined">
              Close
            </Button>
            {isSuccess && (
              <Button onClick={handleSave} color="primary" variant="contained">
                Save
              </Button>
            )}
          </Box>
        </MuiBox>
      </Modal>
    </>
  );
};

export default IndividualDetails;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import logo from "../../assets/logos/logo.svg";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";
import { getStates } from "../../services/location";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { createUser } from "../../services/auth";
import { colors } from "../../styles/colors";
import { setAuthenticated } from "../../features/global/globalSlice";
import { useDispatch } from "react-redux";
interface FormData {
  name: string;
  dob: string;
  gender: string;
  state: string;
  district: string;
  activity_source: string;
  phone_number: string;
}

interface District {
  id: string;
  name: string;
}

interface StateOption {
  id: string;
  name: string;
  districts: District[];
}

const initialFormData: FormData = {
  name: "",
  dob: "",
  gender: "",
  state: "",
  district: "",
  activity_source: "",
  phone_number: "",
};

const formSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  dob: Yup.date().required("Date of Birth is required"),
  gender: Yup.string()
    .oneOf(["Male", "Female", "Other"], "Select a valid gender")
    .required("Gender is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  activity_source: Yup.string().required("Activity Source is required"),
  phone_number: Yup.string().required("Phone number is required"),
});

const getMaxDate = () => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - 18);
  return today.toISOString().split("T")[0];
};

const UserDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { number } = location.state || {};

  const [formData, setFormData] = useState<FormData>({
    ...initialFormData,
    phone_number: number || "",
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [states, setStates] = useState<StateOption[]>([]);
  const [districts, setDistricts] = useState<District[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchStates = async () => {
      setLoading(true);
      try {
        const response = await getStates();
        const stateOptions: StateOption[] = response.map(
          (state: { id: number; name: string; districts: any[] }) => ({
            id: state.id.toString(),
            name: state.name,
            districts: state.districts.map((district) => ({
              id: district.id.toString(),
              name: district.name,
            })),
          })
        );
        setStates(stateOptions);
      } catch (error) {
        console.error("Error fetching states:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const selectedState = states.find((state) => state.id === formData.state);
    setDistricts(selectedState ? selectedState.districts : []);
  }, [formData.state, states]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value as string,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await formSchema.validate(formData, { abortEarly: false });
      setErrors({});
      setLoading(true);
      const response = await createUser(formData);
      if (response.ok) {
        const content = await response.json();
        localStorage.setItem("user-details", JSON.stringify(content));
        dispatch(setAuthenticated(true));
        navigate("/patient-details");
      }
    } catch (validationError) {
      if (validationError instanceof Yup.ValidationError) {
        const newErrors: Partial<FormData> = {};
        validationError.inner.forEach((err) => {
          if (err.path) newErrors[err.path as keyof FormData] = err.message;
        });
        setErrors(newErrors);
      }
    } finally {
      setLoading(false);
    }
  };
  if(!number){
    return <Navigate to='/login' replace={true}/>
  }
  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Box display="flex" flexDirection={"column"} justifyContent="center" alignItems={"center"}>
      <img src={logo} alt="Logo" style={{ width: "50%", maxWidth: "200px" }} />
      <Typography variant="h3">
          CoughSync
        </Typography>
      </Box>
      
      <Typography variant="h5" textAlign="center" gutterBottom>
        User Details
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Box>

        <Box mb={2}>
          <TextField
            fullWidth
            label="Date of Birth"
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            error={!!errors.dob}
            helperText={errors.dob}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: getMaxDate() }}
          />
        </Box>

        <Box mb={2}>
          <TextField
            fullWidth
            select
            label="Gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            error={!!errors.gender}
            helperText={errors.gender}
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Transgender</MenuItem>
          </TextField>
        </Box>

        <Box mb={2}>
          <TextField
            fullWidth
            select
            label="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
            error={!!errors.state}
            helperText={errors.state}
          >
            <MenuItem value="">Select State</MenuItem>
            {states.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box mb={2}>
          <Autocomplete
            options={districts}
            getOptionLabel={(option) => option.name}
            value={
              districts.find((district) => district.id === formData.district) ||
              null
            }
            onChange={(event, newValue) => {
              setFormData({
                ...formData,
                district: newValue ? newValue.id : "",
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="District"
                error={!!errors.district}
                helperText={errors.district}
                fullWidth
              />
            )}
          />
        </Box>

        <Box mb={2}>
          <TextField
            fullWidth
            select
            label="Activity Source"
            name="activity_source"
            value={formData.activity_source}
            onChange={handleChange}
            error={!!errors.activity_source}
            helperText={errors.activity_source}
          >
            <MenuItem value="">Select an option</MenuItem>
            <MenuItem value="100 days Campaign">
              100 days Campaign
            </MenuItem>
          </TextField>
        </Box>

        <Button
          sx={{
            backgroundColor: colors.primary,
            color: colors.secondary,
          }}
          type="submit"
          variant="contained"
          fullWidth
          disabled={loading}
          startIcon={loading ? <CircularProgress size="1rem" /> : null}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </form>
    </Container>
  );
};

export default UserDetails;
